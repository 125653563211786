/* eslint-disable prettier/prettier */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  getSwanStylesheetUrl,
  SWAN_STYLE_KEY_MAP,
  SWAN_BASE_URL_MAP,
  Link,
} from '@vp/swan';
import { isProduction } from '@vp/digital-environment-lib';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: '100%',
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
};
const spanStyle = {
  marginRight: '.4rem',
};

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Not found</title>
        {
          <link key={getSwanStylesheetUrl(SWAN_STYLE_KEY_MAP.button, undefined,  SWAN_BASE_URL_MAP.default.cdn)}
           rel="stylesheet" type="text/css"
            href={(getSwanStylesheetUrl(SWAN_STYLE_KEY_MAP.button, undefined,  SWAN_BASE_URL_MAP.default.cdn)) as string} />
        }
      </Helmet>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji" style={spanStyle}>
          😔
        </span>{' '}
        we couldn’t find what you were looking for.
        <br />
        {!isProduction() ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}> 
              src/pages/
            </code> or <code style={codeStyles}>src/components/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link href="/page-index">
        <Button skin="primary">
          Go to page index
        </Button>
        </Link>
      </p>
    </main>
  );
};

export default NotFoundPage;
